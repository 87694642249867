import { getDbTransaction } from '~/utils/IndexedDb/IndexedDb'

export async function backupAccountSecret(userId: number, value: string) {
  const transaction = await getDbTransaction('AccountSwap', 2)
  const store = transaction.objectStore('secret', 'readwrite')

  return store.put({ userId, value })
}

export async function retrieveAccountSecretFromUserId(userId: number) {
  const transaction = await getDbTransaction('AccountSwap', 2)
  const store = transaction.objectStore('secret', 'readonly')

  return store.get(userId)
}
